export default function config() {
  const ACCESS_ABILITY_SETTINGS_ORDERS = { action: 'change', subject: 'Catalog' }
  const MODULE_NAME = 'dispatch'

  const quoteStatusBar = [
    {
      icon: 'LPickIcon',
      title: 'Pick',
      iconSize: '26',
      id: 1,
    },
    {
      icon: 'LBoxIcon',
      title: 'Pack',
      iconSize: '26',
      id: 2,
    },
    {
      icon: 'LTruckIcon',
      title: 'Dispatch',
      iconSize: '26',
      id: 3,
    },
  ]
  return {
    MODULE_NAME,
    quoteStatusBar,
    ACCESS_ABILITY_SETTINGS_ORDERS,
  }
}
