<template>
  <div
    class="bg-white mb-1 w-100"
    style="border-radius: 5px; padding: 12px; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);"
  >
    <h3 class="font-weight-bolder text-body mt-1">
      {{ $t('Items') }}
    </h3>
    <by-container
      :order-item="order_items"
    />
  </div>
</template>
<script>

import ByContainer from '@/views/main/warehouse/main-order/components/tables/ByContainer.vue'
import config from '@/views/main/warehouse/view/dispatch/dispatchConfig'

export default {
  name: 'SignatureItems',
  components: {
    ByContainer,
  },
  computed: {
    order_items() {
      return this.$store.state[this.MODULE_NAME].dispatchData?.order_items || []
    },
  },
  setup() {
    const { MODULE_NAME } = config()
    return { MODULE_NAME }
  },
}
</script>
