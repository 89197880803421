<template>
  <div>
    <div class="bg-white rounded-lg">
      <span class="font-weight-bolder font-medium-5 d-block pt-2 pl-1">
        {{ $t('Dispatch') }}
      </span>
      <b-row class="mt-1 pb-1">
        <b-col md="9">
          <b-col
            class="d-flex align-items-center justify-content-between"
          >
            <span class="w-50 font-weight-bolder">
              {{ $t('Date') }}
            </span>
            <span class="w-50 font-weight-bolder">
              {{ dispatchData.event_information_dispatch_date ? format(new Date(dispatchData.event_information_dispatch_date),'MMMM d, yyyy, hh:mm aaa') : '&mdash;' }}
            </span>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mt-1"
          >
            <span class="w-50 font-weight-bolder">
              {{ $t('Method') }}
            </span>
            <span class="w-50">
              {{ getValueFromGivenObjectByKey(dispatchData, 'dispatch_method.name', '&mdash;') }}
            </span>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mt-1"
          >
            <span class="w-50 font-weight-bolder">
              {{ $t('Dispatch Location') }}
            </span>
            <span class="w-50 font-weight-bolder">
              {{ getValueFromGivenObjectByKey(dispatchData, 'dispatch_warehouse.label', '&mdash;') }}
            </span>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { BCol, BRow } from 'bootstrap-vue'
import { format } from 'date-fns'
import config from '@/views/main/warehouse/view/dispatch/dispatch-signature/signatureConfig'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'

export default {
  name: 'SignatureDispatch',
  components: { BCol, BRow },
  computed: {
    dispatchData() {
      return this.$store.state[this.MODULE_NAME].dispatchData
    },
  },
  methods: { getValueFromGivenObjectByKey, format },
  setup() {
    const { MODULE_NAME } = config()
    return { MODULE_NAME }
  },
}
</script>
