<template>
  <div class="w-100">
    <div class="bg-white px-1 rounded-lg">
      <span class="font-weight-bolder font-medium-5 d-block pt-2">
        {{ $t('Order Information') }}
      </span>
      <b-row
        class="mt-1 pb-1"
      >
        <div class="d-flex justify-content-between w-100">
          <b-col md="6">
            <b-row>
              <b-col mb="12">
                <b-col
                  mb="6"
                  class="d-flex align-items-center justify-content-between"
                >
                  <span class="w-50 font-weight-bolder">
                    {{ $t('Customer') }}
                  </span>
                  <span class="w-50">
                    <feather-icon
                      :icon="checkIconType"
                      size="16"
                      style="margin-bottom: 5px;"
                    />
                    {{ getValueFromGivenObjectByKey(dispatchData, 'customer.cust_name_dba', '&mdash;') }}
                  </span>
                </b-col>
                <b-col
                  mb="6"
                  class="d-flex align-items-center justify-content-between mt-1"
                >
                  <span class="w-50 font-weight-bolder">
                    {{ $t('Placed by') }}
                  </span>
                  <span class="w-50">
                    {{ getValueFromGivenObjectByKey(dispatchData, 'billing_contact.fullName', '&mdash;') }}
                  </span>
                </b-col>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col mb="8">
                <b-col
                  mb="8"
                  class="d-flex align-items-center justify-content-between"
                >
                  <span class="w-50 font-weight-bolder">
                    {{ $t('Agent') }}
                  </span>
                  <span class="w-50">
                    {{ getValueFromGivenObjectByKey(dispatchData, 'agent', '&mdash;') }}
                  </span>
                </b-col>
                <b-col
                  mb="8"
                  class="d-flex align-items-center justify-content-between mt-1"
                >
                  <span class="w-50 font-weight-bolder">
                    {{ $t('PO/Event Ref.') }}
                  </span>
                  <span class="w-50">
                    {{ getValueFromGivenObjectByKey(dispatchData, 'reference', '&mdash;') }}
                  </span>
                </b-col>
              </b-col></b-row>
          </b-col>
        </div>
        <div class="d-flex justify-content-between w-100">
          <b-col
            v-for="field in ['warehouseNotes']"
            :key="field"
            md="6"
            class="mt-1"
          >
            <component
              :is="RentalSalesPickFields[field].type"
              v-model="warehouseNote"
              style="width: 100%"
              v-bind="getProps(field, RentalSalesPickFields)"
              @change="valueOfNote(warehouseNote)"
            />
          </b-col>
          <b-col
            v-for="field in ['orderNotes']"
            :key="field"
            md="6"
            class="mt-1"
          >
            <component
              :is="RentalSalesPickFields[field].type"
              :value="getOrderNote"
              :disabled="true"
              style="width: 100%"
              v-bind="getProps(field, RentalSalesPickFields)"
            />
          </b-col>
        </div>
      </b-row>
    </div>
  </div>
</template>
<script>
import { BCol, BRow } from 'bootstrap-vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import { format } from 'date-fns'
import config from '@/views/main/warehouse/view/dispatch/dispatch-signature/signatureConfig'

export default {
  components: { BCol, BRow },
  data() {
    return {
      // Dynamic variables
      warehouseNote: '', // Holds the editable note value
      orderNote: 'This is a static order note.', // Example static value
      RentalSalesPickFields: {
        warehouseNotes: { type: 'textarea' },
        orderNotes: { type: 'textarea' },
      },
    }
  },
  computed: {
    dispatchData() {
      return this.$store.state[this.MODULE_NAME].dispatchData
    },
    checkIconType() {
      const status = 1
      if (status === 1) {
        return 'LTickIcon'
      }
      if (status === 2) {
        return 'LNoIcon'
      }
      if (status === 3) {
        return 'LClockIcon'
      }
      return 'LWarningIcon'
    },
    getOrderNote() {
      return this.orderNote
    },
  },
  methods: {
    getValueFromGivenObjectByKey,
    valueOfNote(note) {
      console.log('Note changed:', note)
    },
    getProps(field, fields) {
      return {
        placeholder: `Enter ${field.replace(/([A-Z])/g, ' $1')}`,
        rows: 5,
        style: {
          border: '1px solid rgba(221, 227, 231, 1)', padding: '0.8rem 1rem', borderRadius: '0.375rem',
        },
        ...(fields[field] || {}),
      }
    },
  },
  setup() {
    const { MODULE_NAME } = config()
    return { MODULE_NAME }
  },
}
</script>
