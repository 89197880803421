<template>
  <div
    id="buttonWrapper"
    class="d-flex pb-1 justify-content-between w-100 p-1 buttonWrapper"
  >
    <div class="d-flex align-items-center">
      <b-button
        class="cancelBtn font-medium-1 font-weight-bolder"
        variant="outline-primary"
        @click="$router.push({ name: 'home-warehouse-view' })"
      >
        {{ $t("Back to List") }}
      </b-button>
    </div>
    <div class="d-flex">
      <b-button
        variant="secondary"
        class="font-medium-1 font-weight-bold px-3 d-flex align-items-center justify-content-center ml-2"
        @click="printContent($event)"
      >
        <feather-icon
          icon="LPrintIcon"
          style="margin-right: 10px"
          size="20"
        />
        <span>{{ $t("Print Pack List") }}</span>
      </b-button>

      <b-button
        variant="success"
        class="d-flex justify-content-center ml-2 align-items-center"
        @click="sendEmail"
      >
        <feather-icon
          icon="LSignatureIcon"
          style="margin-right: 10px; margin-bottom: 5px;"
          size="
          20"
        />
        <span>{{ $t('Email signed documents') }}</span>
      </b-button>
    </div>
  </div>
</template>
<script>
import { BButton } from 'bootstrap-vue'
import config from '@/views/main/warehouse/view/dispatch/dispatch-signature/signatureConfig'

export default {
  components: { BButton },
  methods: {
    printContent(event) {
      event.preventDefault()
      window.print()
    },
    sendEmail() {
      this.sendNotification(this, { id: 1 }, `${this.MODULE_NAME}/sendEmailSignedDocument`)
        .then(response => {
        })
        .catch(err => {
          this.toastErrorNotification(this, err.response?.data.message, err.response.status)
        }).finally(() => {
        })
    },
  },
  setup() {
    const { MODULE_NAME } = config()
    return { MODULE_NAME }
  },
}
</script>

<style scoped>
@media print {
  .buttonWrapper {
    display: none !important;
  }
}

</style>
