<template>
  <div style="padding: 0 12px">
    <quote-status-bar
      :item="quoteStatusItem"
      :quote-status-bar="quoteStatusBarItems"
      class="statusBar"
    />
    <b-row class="mt-2">
      <div
        class="d-flex w-100"
        style="gap: 12px"
      >
        <b-col
          cols="6"
          class="px-0"
        >
          <signature-dispatch class="signatureDispatch" />
        </b-col>
        <b-col
          cols="6"
          class="px-0"
        >
          <signature-return />
        </b-col>
      </div>
      <signature-order-info
        class="mt-2 signatureOrderInfo"
      />
      <div
        id="printContent"
        style="display: contents"
      >
        <signature-item
          class="mt-2 signatureItem"
        />
      </div>
      <signature-image
        class="mt-2 signatureImage"
      />
      <signature-bottom />
    </b-row>
  </div>
</template>
<script>

import mainConfig from '@/views/main/warehouse/config'
import dispatchConfig from '@/views/main/warehouse/view/dispatch/dispatchConfig'
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import { BCol, BRow } from 'bootstrap-vue'
import SignatureDispatch
from '@/views/main/warehouse/view/dispatch/dispatch-signature/components/SignatureDispatch.vue'
import SignatureReturn from '@/views/main/warehouse/view/dispatch/dispatch-signature/components/SignatureReturn.vue'
import SignatureOrderInfo
from '@/views/main/warehouse/view/dispatch/dispatch-signature/components/SignatureOrderInfo.vue'
import SignatureItem from '@/views/main/warehouse/view/dispatch/dispatch-signature/components/SignatureItems.vue'
import SignatureImage from '@/views/main/warehouse/view/dispatch/dispatch-signature/components/SignatureImage.vue'
import SignatureBottom from '@/views/main/warehouse/view/dispatch/dispatch-signature/components/SignatureBottom.vue'

export default {
  components: {
    SignatureBottom,
    SignatureImage,
    SignatureItem,
    SignatureOrderInfo,
    BCol,
    BRow,
    QuoteStatusBar,
    SignatureDispatch,
    SignatureReturn,
  },
  computed: {
    dispatchData() {
      return this.$store.state[this.MODULE_NAME].dispatchData
    },
    quoteStatusItem() {
      return this.dispatchData && {
        state: this.dispatchData.fulfillment_state,
        status: this.dispatchData.fulfillment_status,
      }
    },
    quoteStatusBarItems() {
      return this.quoteStatusItemsMerge(this.dispatchData.states)
    },
  },

  setup() {
    const { quoteStatusItemsMerge } = mainConfig()
    const { MODULE_NAME } = dispatchConfig()

    return {
      quoteStatusItemsMerge,
      MODULE_NAME,
    }
  },
}
</script>

<style scoped>
@media print {
  * {
    margin: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .statusBar, .signatureReturn{
    display: none !important;
  }
}

</style>
